
<template>
  <v-app>
    <v-main class="login_background">
      <card-login card_color='white' logo_class='white'  title="Confirme seu e-mail">
        <template v-slot:form>
          <div class="title d-flex justify-center">
            E-mail validado com sucesso. Sua conta ja está liberada.
          </div>
        </template>
        <template v-slot:control>
          <v-row>
            <v-col>
              <div class="d-flex justify-center">
                <v-btn text color="primary" @click="$router.push({name:'Login'})" rounded>Entrar<v-icon right>mdi-arrow-right</v-icon></v-btn>
              </div>
            </v-col>
          </v-row>
        </template>
      </card-login>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  components: {
    CardLogin: () => import("@/_auth/components/containers/_auth_card_01.vue"),
  },
  data() {
    return {
      key: null
    }
  },
  methods: {
    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  watch: {
    data(nv,ov) {
      if (nv){
      } else if (nv == false){
      }
    }
  },
}
</script>

<style>
.login_background {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  background: url('../../assets/background_login.png');
}
</style>